import React from 'react';
import './Sidebar.css';
import logo from '../img/Lilly_logo.png';

const Sidebar = ({ setActivePage }) => {
  return (
    <div className="sidebar">
      <img src={logo} alt="Sidebar Top" className="sidebar-image" />
      <b className="logo">Med Affairs</b>
      <div className="sidebar-line"></div>
      <div>
        <ul>
          <li>
            <button onClick={() => setActivePage('Chatbot')} className="sidebar-link">+ New Event</button>
          </li>
          <li>
            <button onClick={() => setActivePage('Upload')} className="sidebar-link">Upload Files</button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
