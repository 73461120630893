import  { AuthProvider }  from '@elilillyco/spa_auth';
import Utils from "../Common/Utils";
const env = Utils.getEnvVars();

const config = {
   clientId: env.clientId,
   // openIdCallback: '/auth/callback',
   // authority: 'https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580',
   // scopes: env.scopes, 
   openidPostLogoutRedirectUri: "/",
   // isGatewayScopeRequired: false,
   // gatewayScopeType: null,
   cacheLocation: "localStorage",
   logLevel: "ERROR"
   
}
export const authProvider = new AuthProvider(config);