import React, { useState } from 'react';
import './Header.css';



const Header = () => {
    const [activeTab, setActiveTab] = useState('home');
  
    const handleTabClick = (tab) => {
      setActiveTab(tab);

    };
  
    return (
      <header className="header">
        <div className="header-container">
          <div className="tabs">
            <div
              className={`tab ${activeTab === 'home' ? 'active' : ''}`}
              onClick={() => handleTabClick('home')}
            >
              Home
            </div>
            <div
              className={`tab ${activeTab === 'about' ? 'active' : ''}`}
              onClick={() => handleTabClick('about')}
            >
              About
            </div>
            <div
              className={`tab ${activeTab === 'services' ? 'active' : ''}`}
              onClick={() => handleTabClick('services')}
            >
              Services
            </div>
            <div
              className={`tab ${activeTab === 'contact' ? 'active' : ''}`}
              onClick={() => handleTabClick('contact')}
            >
              Contact
            </div>
          </div>
        </div>
      </header>
    );
  };
  
  export default Header;