import React, { useState, useRef, useEffect } from 'react';
import './Chatbot.css';
import Dropdown from './dropdown';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';

function LoadingIcon() {
  return <div className="spinner"></div>;
}

const Chatbot = () => {
  const [formData, setFormData] = useState({ prompt: "" });
  const chatEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState([]);
  const [conversationLog, setConversationLog] = useState([]);  // New state to log the conversation

  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  fetch("https://med-affair-webapp-01.azurewebsites.net", requestOptions);

  const handleChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePayload = (arr, type, next) => {
    let _arr = [...arr];
    _arr.push({ type: type, line: next, sender: type });
    setPayload(_arr);
    
    // Log conversation entry with selected option
    setConversationLog((prevLog) => [
      ...prevLog,
      { type: type, content: next, selectedOption: formData.selectedOption1 }
    ]);

    return _arr;
  };

  const options = ['Select', 'OLUMIANT', 'VERZENIOS', "MOUNJARO", "CIALIS", "FORSTEO", "CYRAMZA", "OMVOH", "TAUVID", "AMYVID"];

  const handleSelect = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedOption1: selectedOption
    }));
    console.log('Selected option:', selectedOption);
  };

  const getResponse = async () => {
    setLoading(true);
    let _arr = handlePayload([...payload], "user", formData["prompt"]);
    setFormData((prevFormData) => ({ ...prevFormData, prompt: "" }));

    const data = {
      question: formData["prompt"],
      medicineType: formData["selectedOption1"]
    };
    const queryParams = new URLSearchParams(data).toString();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: queryParams,
    };

    fetch(`https://med-affair-webapp-01.azurewebsites.net/ask?${queryParams}`, requestOptions)
      .then((response_f) => response_f.json())
      .then((data) => {
        const response = data.response[1].content;
        console.log("Bot RESPONSE:", response);
        handlePayload([..._arr], "bot", response);
        setLoading(false);
      });
  };

  const createMarkup = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  // Helper function to parse HTML content with structured formatting
  const parseHtmlToDocx = (text) => {
    const parser = new DOMParser();
    
    // Split the text by lines first, so we can keep line breaks intact
    const lines = text.split('\n');

    return lines.flatMap((line, lineIndex) => {
        // Parse each line as HTML to handle any tags within the line
        const doc = parser.parseFromString(line, 'text/html');
        const elements = Array.from(doc.body.childNodes);

        // Convert each HTML element within the line
        const parsedElements = elements.flatMap((el) => {
            switch (el.nodeName) {
                case "#text":
                    return new TextRun(el.textContent);
                case "STRONG":
                    return new TextRun({ text: el.textContent, bold: true });
                case "EM":
                    return new TextRun({ text: el.textContent, italics: true });
                case "A":
                    return new TextRun({
                        text: el.textContent,
                        color: "0000FF",
                        underline: true,
                        style: "Hyperlink",
                    });
                default:
                    return new TextRun(el.textContent);
            }
        });

        // Add a break after each line except the last one
        return [
            ...parsedElements,
            new TextRun({ text: "", break: lineIndex < lines.length - 1 ? 1 : 0 })
        ];
    });
};


  // Updated function to download conversation as a Word document
  const downloadConversationAsWord = () => {
    const docSections = [];
    let lastSelectedOption = null;

    conversationLog.forEach((log) => {
      // Check if the dropdown context changed, and add a new context heading if it did
      if (log.selectedOption !== lastSelectedOption) {
        lastSelectedOption = log.selectedOption;

        docSections.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `Conversation Context: ${log.selectedOption || 'None'}`,
                bold: true,
                size: 28,
                underline: { type: "single" },
              }),
            ],
            spacing: { after: 400 },
          })
        );
      }

      // Sender label with conversational colors for User and Bot
      docSections.push(
        new Paragraph({
          children: [
            new TextRun({
              text: `${log.type === "user" ? "User" : "Bot"}:`,
              bold: true,
              color: log.type === "user" ? "d35400" : "7f8c8d",
            }),
          ],
          spacing: { after: 200 },
        })
      );

      // Add message content, formatted to match the web UI’s inline HTML structure
      docSections.push(
        new Paragraph({
          children: parseHtmlToDocx(log.content),
          border: {
            top: log.type === "user" ? { color: "ff6347", size: 6 } : { color: "d3d3d3", size: 6 },
          },
          shading: {
            type: "solid",
            color: log.type === "user" ? "ffe3d3" : "f1f1f1",
          },
          spacing: { after: 300 },
          alignment: log.type === "user" ? "left" : "left",  // Align messages based on sender
        })
      );
    });

    const doc = new Document({
      sections: [
        {
          children: docSections,
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "conversation_log.docx");
    });
  };

  // Auto-scroll to the latest message
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [payload]);

  return (
    <div className="chatbot">
      <div className="dropdown">
        <Dropdown options={options} onSelect={handleSelect} />
      </div>
      <div className="chatbot-messages">
        {payload.map((item, index) => (
          <div key={index} className={`message ${item.sender}`} style={{ whiteSpace: "pre-wrap" }}>
            <p dangerouslySetInnerHTML={{ __html: createMarkup(item.line).join('') }}></p>
          </div>
        ))}
        {loading && <LoadingIcon />}
      </div>
      <div ref={chatEndRef} />
      <div className="chatbot-input flex justify-end mr-5 ml-20">
        <input
          type="text"
          value={formData.prompt}
          name="prompt"
          placeholder="Type here..."
          onChange={handleChange}
          onKeyDown={(e) => e.key === 'Enter' && formData.selectedOption1 && formData.selectedOption1 !== 'Select' && getResponse()}
        />
        <button onClick={getResponse} disabled={!formData.selectedOption1 || formData.selectedOption1 === 'Select'}>Send</button>
        <button onClick={downloadConversationAsWord} className="download-btn">Download Chat</button> {/* New download button for Word */}
      </div>
    </div>
  );
};

export default Chatbot;



// // src/components/Chatbot.js
// import React, { useState,useRef, useEffect } from 'react';
// import './Chatbot.css';
// import Dropdown from './dropdown';
// // require('dotenv').config();

// function LoadingIcon() {
//   return (
//     <div className="spinner"></div>
//   );
// }

// const Chatbot = () => {
//   const [formData, setFormData] = useState({ prompt: "" });
//   let searchText = formData;
//   let { prompt } = searchText;
//   const chatEndRef = useRef(null);

//   const [loading, setLoading] = useState(false);
//   const [vTextVisible, setVTextVisible] = useState(false);
//   const [payload, setPayload] = useState([]);

//   const requestOptions = {
//     method: "GET",
//     headers: { "Content-Type": "application/x-www-form-urlencoded" },
//   };

//   // const url1 = `${process.env.API_URL}`;
//   fetch("https://med-affair-webapp-01.azurewebsites.net", requestOptions);
//   //fetch("http://localhost:5000", requestOptions);
//   // fetch(url1, requestOptions)

//   const handleChange = (event) => {
//     console.log("Event Data: ", event.target.value);
//     setFormData((prevFormData) => {
//       return {
//         ...prevFormData,
//         [event.target.name]: event.target.value,
//       };
      
//     });
//   };

//   const handlePayload = (arr, type, next) => {
//     let _arr = arr;
//     _arr.push({ type: type, line: next, sender: type });
//     setPayload(_arr);
//     return _arr;
//   };

//   const options = ['Select', 'OLUMIANT', 'VERZENIOS', "MOUNJARO", "CIALIS", "FORSTEO", "CYRAMZA", "OMVOH", "TAUVID", "AMYVID"];

//   const handleSelect = (selectedOption) => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       selectedOption1: selectedOption
//     }));
//     console.log('Selected option:', selectedOption);
//   };

//   const getResponse = async (event) => {
//     setLoading(true);
//     let _arr = handlePayload([...payload], "user", formData["prompt"]);
//     setFormData((prevFormData) => ({ ...prevFormData, prompt: "" }));

//     console.log("User QUESTION:", formData["prompt"]);
//     const data = {
//       question: formData["prompt"],
//       medicineType: formData["selectedOption1"]
//     };
//     const queryParams = new URLSearchParams(data).toString();

//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/x-www-form-urlencoded" },
//       body: queryParams,
//     };
//     // const url2 = `${process.env.API_URL}/ask?${queryParams}`;
//     // fetch("http://localhost:5000/ask?" + queryParams, requestOptions)
//     fetch(`https://med-affair-webapp-01.azurewebsites.net/ask?` + queryParams, requestOptions)
//     // fetch(url2, requestOptions)
//     .then(function (response_f) {
//         return response_f.json();
//       })
//       .then(function (data) {
//         const items = data;
//         console.log("Message", items["response"][0].content);
//         const response = items["response"][1].content;
//         handlePayload([..._arr], "bot", response);
//         setVTextVisible(true);
//         setLoading(false);
//         console.log("Received :v");
//         console.log(response);
//       });
//   };

//   const createMarkup = (text) => {
//     const urlRegex = /(https?:\/\/[^\s\)]+)/g;
//     const parts = text.split(urlRegex);
//     const pattern = /Reference for answer: (.*)/g;
//     const refs = text.split(pattern);
//     console.log(parts)
//     return parts.map((part, index) =>
//       urlRegex.test(part) ? (
//         `<a key=${index} href=${part} target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">Please click on this link to navigate to document.</a>`
//       ) : (
//         part
//       )  
//     );
//     return refs.map((ref, index) =>
//       pattern.test(ref) ? (
//         <a key={index} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
//           {ref}
//         </a>
//       ) : (
//         ref
//       )  
//     );
//   };

//     // Auto-scroll to the latest message
//     useEffect(() => {
//       chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//     }, [searchText]);

//   // const handleSendMessage = () => {
//   //   if (input.trim()) {
//   //     setMessages([...messages, { sender: 'user', text: input }]);
//   //     setInput('');
//   //     // Simulate a response from the chatbot
//   //     setTimeout(() => {
//   //       setMessages(prevMessages => [
//   //         ...prevMessages,
//   //         { sender: 'bot', text: 'This is a response from the bot.' }
//   //       ]);
//   //     }, 1000);
//   //   }
//   // };
  
//   return (
    
//     <div className="chatbot">
//       <div className={`dropdown`}>
//         <Dropdown options={options} onSelect={handleSelect} />
//       </div>
//       <div className="chatbot-messages">
        
//         {payload.map((item, index) => (
//           <div key={index} className={`message ${item.sender}`} style={{ whiteSpace: "pre-wrap" }} >
//             <p dangerouslySetInnerHTML={{__html: createMarkup(item.line)}}></p>
//           </div>
//         ))}
//         {loading ? (
//             <div>
//               <LoadingIcon/>
//             </div>
//           ) : (
//             <></>
//           )}
//       </div>
//       <div ref={chatEndRef} />
      
//       <div className="chatbot-input flex justify-end mr-5 ml-20">
//         <input
//           type="text"
//           value={formData.prompt}
//           name='prompt'
//           placeholder='Type here...'
//           onChange={(e) => handleChange(e)}
//           onKeyDown={(e) => e.key === 'Enter' && getResponse()}
//         />
//         <button onClick={getResponse}>Send</button>
//       </div>
     
//     </div>
//   );

// };


// export default Chatbot;
