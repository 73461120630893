import'@elilillyco/ux-lds-react';
import '@elilillyco/ux-lds-react/src/css/index.css';

import Home from "../pages/Home"


const Content = ({ initLoad, notFound, cookieFile, setCookieFile, initloading, validPersona, setValidPersona, setpersonaAccess, personaAccess,subcontenttab, userName }) => {
  return (
      (subcontenttab && subcontenttab === "Home") ? <Home/> : <></>    
  )  
}
export default Content;