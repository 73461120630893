import decode from 'jwt-decode';
import config from "../config.json";

const Environment = config.Environment;

class UtilsWrap {
  getCurrentEnv() {
    if (process.env.APP_ENV) return process.env.APP_ENV;
    // Production
    if (
      window.location.hostname.includes('prod')
      || window.location.hostname === ''
      || window.location.hostname === ''
    ) {
      return 'prod';
    }
    // Staging
    if (
      window.location.hostname.includes('qa')
      || window.location.hostname === ''
      || window.location.hostname === ''
    ) {
      return 'qa';
    }
    // Develop
    return 'dev';
  }

  getEnvVars() {
    const env = this.getCurrentEnv();
    const envVariables = Object.assign({}, Environment);
    return envVariables[env];
  }

  decodeJWT(token) {
    console.log('utils token ', token)
    return decode(token);
  }

  hasSpecialCharacters(filename) {
    // Regular expression to match any character other than letters, numbers, underscores, and periods
    var regex = /[^\w.]/;

    // Test the filename against the regular expression
    return regex.test(filename);
  }

  setCookie(cookieName, cookieValue, expirationDays) {
    var d = new Date();
    d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
  }

  getCookie(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
      var c = cookieArray[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setSession(cookieName, cookieValue) {
    sessionStorage.setItem(cookieName, cookieValue);
  }

  getSession(cookieName) {
    return sessionStorage.getItem(cookieName);
  }

  checkAndGetCookie() {
    !this.getCookie("filesubmit") && this.setCookie("filesubmit", JSON.stringify({ dropFileName: [] }), 1);
    return this.getCookie("filesubmit") && JSON.parse(this.getCookie("filesubmit"));
  }

  checkAndGetSession() {
    !this.getSession("filesubmit") && this.setSession("filesubmit", JSON.stringify({ dropFileName: [] }), 1);
    return this.getSession("filesubmit") && JSON.parse(this.getSession("filesubmit"));
  }    
}

const Utils = new UtilsWrap()
export default Utils;