// Upload.js
import React, { useState } from 'react';
import './uploadfile.css'

const Upload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (file) {
      // Handle file upload
      console.log('Uploading:', file);
    }
  };

  return (
    <div className='document-uploader'>
      <h1>Upload Document</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit" className='button'>Upload</button>
      </form>
    </div>
  );
};

export default Upload;
